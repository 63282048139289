import React from "react";

const News = () => {
    return (
        <>
            <h1 className="text-center mb-5 mt-5 display-1">Artigos</h1>
            <div className="container-fluid">
                <div
                    className="row mt-2 mb-2 rounded"
                    style={{
                        minHeight: "400px",
                        backgroundColor: "rgb(250, 249, 246)",
                    }}
                >
                    <div className="col-md-6 text-center">
                        <h3>Saúde Mental</h3>
                        <img
                            src="/campanha/problemas_mentais.avif"
                            alt=""
                            className="p-5 img-fluid rounded mx-auto d-block"
                            style={{ maxHeight: "500px" }}
                        />
                    </div>
                    <div className="col-md-6" style={{ textAlign: "justify" }}>
                        <p className="fs-3">
                            A saúde mental é um problema crescente em nossa
                            sociedade. Infelizmente, muitas pessoas não estão
                            recebendo o tratamento que necessitam para tratar
                            sua saúde mental. Isso pode causar problemas sérios
                            de saúde mental, que podem afetar a qualidade de
                            vida das pessoas. É importante que as pessoas
                            busquem ajuda profissional quando necessário.
                        </p>
                        <p className="fs-3">
                            Além disso, muitas vezes as pessoas estão
                            enfrentando problemas de saúde mental sem nem mesmo
                            saber. Os sintomas podem variar de pessoa para
                            pessoa, mas alguns sintomas comuns incluem
                            ansiedade, depressão, problemas para dormir,
                            estresse crônico e problemas de memória. É
                            importante estar atento a esses sintomas e procurar
                            um profissional da área de saúde mental se você ou
                            alguma pessoa próxima estiver sentindo esses
                            sintomas.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div
                    className="row mt-2 mb-2 rounded"
                    style={{
                        minHeight: "400px",
                        backgroundColor: "rgb(195, 177, 225)",
                    }}
                >
                    <div className="col-md-6 text-center">
                        <h3>Câncer do colo do útero</h3>
                        <img
                            src="/campanha/utero_mulher.avif"
                            alt=""
                            className="p-5 img-fluid rounded mx-auto d-block"
                            style={{ maxHeight: "500px" }}
                        />
                    </div>
                    <div className="col-md-6" style={{ textAlign: "justify" }}>
                        <p className="fs-3">
                            O câncer do colo do útero é um tipo de câncer que
                            ocorre quando as células do colo do útero se
                            multiplicam descontroladamente. O câncer do colo do
                            útero é o segundo tipo mais comum de câncer em
                            mulheres em todo o mundo. Os principais fatores de
                            risco para o câncer do colo do útero incluem
                            infecção persistente pelo vírus do papiloma humano
                            (HPV), tabagismo, uso prolongado da pílula
                            anticoncepcional e vida sexual ativa precoce.
                        </p>
                        <p className="fs-3">
                            Os sintomas iniciais do câncer do colo do útero
                            incluem sangramento vaginal anormal, dor durante a
                            relação sexual, secreção vaginal anormal, distúrbios
                            intestinais e dor na região pélvica. O diagnóstico
                            precoce é essencial para o tratamento do câncer do
                            colo do útero. O tratamento comum inclui cirurgia,
                            radioterapia, quimioterapia e/ou terapia hormonal.
                            Tomar medidas preventivas, como vacinação contra HPV
                            e exames regulares, ajudam a reduzir o risco de
                            desenvolver câncer do colo do útero.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default News;
