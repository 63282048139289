import React, {useState, useEffect, useContext} from "react";
import NavBar from "../Components/Navbar";
import Jumbotron from "../Components/Jumbotron";
import Painel from "../Components/Painel";
import Footer from "../Components/Footer";
import Loading from "../Components/Loading";
import News from "../Components/News";
import { AuthContext } from "../../contexts/auth";

const MainPage = () => {
    const {user, logout} = useContext(AuthContext);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                
                if(user) {
                    setLoading(false);
                } 
            } catch (err) {
                //console.error(err);
                //setLoadingError(true);
            }
        })();
    }, [user]);

    const handleLogout = () => {
        logout();
    };

    if (loading) {
        return (
            <Loading />
        )
    }

    return (
        <div className="container-fluid">
            <NavBar onLogout={handleLogout} role={user.role} currentPage={'inicio'} />
            <Jumbotron name={user.name} role={user.role} />
            <Painel />
            <News />
            <Footer />
        </div>
    )
};

export default MainPage;