import React, {useState, useContext, useEffect} from "react";
import { AuthContext } from "../../contexts/auth";
import NotAuthorizedPage from "../NotAuthorizedPage";
import NavBar from "../Components/Navbar";
import { getGroupsByUser, syncUserToGroup } from "../../services/api";
import Loading from "../Components/Loading";
import GroupsForPatients from "../Components/GroupsForPatients";
import Pagination from "../Components/Pagination";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Appointments = () => {
    const {user, logout} = useContext(AuthContext);

    const [loading, setLoading] = useState(true);

    const [groups, setGroups] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);

    const loadGroups = async () => {
        try {
            setLoading(true);
            const response = await getGroupsByUser(user.id);
            
            setGroups(response.data);
            
            setLoading(false);
        } catch (err) {
            //console.error(err);
            //setLoadingError(true);
        }
    }

    useEffect(() => {
        (loadGroups)();
    }, []);

    const handleMakeAppointment = async(groupId) => {
        try {
            const result = await syncUserToGroup(user.id, groupId);

            console.log(result);
            if(result.status === 200) {
                toast.success('Marcação realizada com sucesso!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if(result.status === 400) {
                toast.error('Não foi possível realizar a marcação!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            setGroups(groups.filter(group => group.id !== groupId));
        } catch (err) {
            //console.error(err);
            //setLoadingError(true);
        }
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = groups.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(groups.length / recordsPerPage);

    if (loading) {
        return (
            <Loading />
        )
    }

    if(user.role !== "ADMIN" && user.role !== "PATIENT") {
        return (
            <NotAuthorizedPage />
        )
    }

    const handleLogout = () => {
        logout();
    };

    return (
        <>
            <NavBar onLogout={handleLogout} role={user.role} currentPage={'patientAppointments'}/>
            <ToastContainer />
            {
                groups.length === 0 ? (
                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-12 mt-5">
                                <div className="alert alert-primary" role={'alert'}>
                                    <h3>
                                        Você não possui nenhum agendamento!
                                    </h3> 
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <GroupsForPatients countGroups={groups.length} groups={currentRecords} loadGroups={loadGroups} handleMakeAppointment={handleMakeAppointment} appointments={true} />
                        
                        <Pagination
                            totalItems={groups.length}
                            itemsPerPage={recordsPerPage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        />
                    </>
                    
                )
            }
        </>
    )
}

export default Appointments;