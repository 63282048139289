import React from "react";
import {RiHealthBookFill} from "react-icons/ri";
import { Link } from "react-router-dom";

const Jumbotron = ({name, role}) => {
    if(role === 'ADMIN' || role === 'EMPLOYEE') {
        return (
            <div className="p-5 mb-1 bg-light rounded-3">
                <div className="container-fluid py-5">
                    <h1 className="display-5 fw-bold">Olá, <span className="text-danger"> {name}</span>, ótimo dia!</h1>
                    <p className="col-md-8 fs-4">
                        Você está logado como {role === 'ADMIN' ? 'administrador' : role === 'EMPLOYEE' ? 'funcionário' : 'Não definido'}. Gerencie os grupos clicando em "Grupos".
                    </p>
                    
                    <Link to="/admin/groups" className="btn btn-lg btn-danger"><RiHealthBookFill /> Grupos</Link>
                </div>
            </div>
        )
    } else if(role === 'PATIENT') {
        return (
            <div className="p-5 mb-1 bg-light rounded-3">
                <div className="container-fluid py-5">
                    <h1 className="display-5 fw-bold">Olá, <span className="text-danger"> {name}</span>, ótimo dia!</h1>
                    <p className="col-md-8 fs-4">
                        Nunca foi tão fácil marcar sua consulta. Clique em "Listar Vagas" para saber mais.
                    </p>
                    <Link to="/patient/groups" className="btn btn-lg btn-primary"><RiHealthBookFill /> Listar Vagas</Link>
                </div>
            </div>
        )
    }
} ;

export default Jumbotron;