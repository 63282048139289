import React, {useState, useContext, useEffect} from "react";
import { AuthContext } from "../../contexts/auth";
import NotAuthorizedPage from "../NotAuthorizedPage";
import NavBar from "../Components/Navbar";
import { getGroups } from "../../services/api";
import Loading from "../Components/Loading";
import Groups from "../Components/Groups";
import Pagination from "../Components/Pagination";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminGroupPage = () => {
    const {user, logout} = useContext(AuthContext);

    const [loading, setLoading] = useState(true);

    const [groups, setGroups] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);

    const loadGroups = async () => {
        try {
            setLoading(true);
            const response = await getGroups(user.role);
            
            setGroups(response.data);
            
            setLoading(false);
        } catch (err) {
            //console.error(err);
            //setLoadingError(true);
        }
    }

    useEffect(() => {
        (loadGroups)();
    }, []);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = groups.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(groups.length / recordsPerPage);

    if (loading) {
        return (
            <Loading />
        )
    }

    if(user.role !== "ADMIN") {
        return (
            <NotAuthorizedPage />
        )
    }

    const handleLogout = () => {
        logout();
    };

    return (
        <>
            <NavBar onLogout={handleLogout} role={user.role} currentPage={'adminGroupPage'} />
            <ToastContainer />
            <Groups countGroups={groups.length} groups={currentRecords} loadGroups={loadGroups}/>
            <div className="row m-1">
                <div className="col-12">
                    <Pagination
                        className=""
                        totalItems={groups.length}
                        itemsPerPage={recordsPerPage}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                </div>
                <div className="col-12">
                    <p className="text-center">Página {currentPage} de {nPages}</p>    
                </div>
            </div>
        </>
    )
}

export default AdminGroupPage;