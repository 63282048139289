import React, { useContext, useEffect, useState } from "react";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    useNavigate,
} from "react-router-dom";

import { AuthProvider, AuthContext } from "./contexts/auth";

import LoginPage from "./pages/LoginPage";

import MainPage from "./pages/MainPage";

import NotFoundPage from "./pages/NotFoundPage";

import Loading from "./pages/Components/Loading";

import AdminGroupPage from "./pages/AdminGroupPage";

import AdminGroupPageCreate from "./pages/AdminGroupPage/create";

import AdminGroupPageEdit from "./pages/AdminGroupPage/Edit";

import PatientGroupPage from "./pages/PatientGroupPage";

import PatientAppointments from "./pages/PatientGroupPage/Appointments";

import PrintUsersOnGroupPage from "./pages/PrintUsersOnGroupPage";

const AppRoutes = () => {
    const Private = ({ children }) => {
        const navigate = useNavigate();
        const { authenticated } = useContext(AuthContext);
        const [authComplete, setAuthComplete] = useState(false);

        useEffect(() => {
            setTimeout(() => {
                setAuthComplete(true);
            }, 1);
        }, []);

        if (!authComplete) {
            return <Loading />;
        } else if (!authenticated) {
            navigate("/login");
            return null;
        } else {
            return children;
        }
    };

    return (
        // prettier-ignore
        <Router>
            <AuthProvider>
                <Routes>
                    <Route exact path="/login" element={<LoginPage />} />
                    <Route exact path="/" element={<Private><MainPage /></Private>} />
                    <Route exact path="/admin/groups" element={<Private><AdminGroupPage /></Private>} />
                    <Route exact path="/admin/groups/create" element={<Private><AdminGroupPageCreate /></Private>} />
                    <Route exact path="/admin/groups/edit/:id" element={<Private><AdminGroupPageEdit /></Private>} />
                    <Route exact path="/admin/print/users/on/group/:id" element={<Private><PrintUsersOnGroupPage /></Private>} />
                    <Route exact path="/patient/groups" element={<Private><PatientGroupPage /></Private>} />
                    <Route exact path="/patient/sync/groups" element={<Private><PatientAppointments /></Private>} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </AuthProvider>
        </Router>
    );
};

export default AppRoutes;
