import React from 'react';

const Pagination = ({ totalItems, itemsPerPage, setCurrentPage, currentPage }) => {

    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const maxPages = 5; // limite de páginas exibidas na barra de paginação

  // lógica para exibir as páginas corretas na barra de paginação
    let startPage, endPage;
    if (totalPages <= maxPages) {
        startPage = 1;
        endPage = totalPages;
    } else {
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
        } else {
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

  // lógica para navegar para a página anterior
    const prevPage = () => {
        if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        }
    };

    // lógica para navegar para a próxima página
    const nextPage = () => {
        if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
        }
    };

    // criar um array com as páginas a serem exibidas na barra de paginação
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className='row justify-content-center w-100'>
            <nav className='mt-2'>
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={prevPage}>Anterior</button>
                    </li>
                    {pageNumbers.map((pageNumber) => (
                    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</button>
                    </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={nextPage}>Próximo</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Pagination