import axios from "axios";

//baseURL: "http://localhost:3006"
//baseURL: "https://backend.wolflan.dev"

export const api = axios.create({
  baseURL: "https://backend.wolflan.dev",
});

export const createSession = async (email, password) => {
  return api.post("/sessions", { email, password });
};

export const getUsers = async () => {
  const url = "/users";

  return api.get(url);
};

export const getGroups = async (role, userId) => {
  const url = "/groups";

  return api.get(url, { params: { role, userId } });
};

export const getGroup = async (id) => {
  const url = `/groups/${id}`;

  return api.get(url);
};

export const createGroup = async (group) => {
  const url = "/groups";

  return api.post(url, group);
};

export const updateGroup = async (id, group) => {
  const url = `/groups/${id}`;

  return api.put(url, group);
};

export const deleteGroup = async (id) => {
  const url = `/groups/${id}`;

  return api.delete(url);
};

export const syncUserToGroup = async (user, group) => {
  const url = "/groups/sync/user";

  return api.post(url, { groupId: group, userId: user });
};

export const getGroupsByUser = async (userId) => {
  const url = `/groups/users/${userId}`;

  return api.get(url);
};

export const getUsersByGroup = async (groupId) => {
  const url = `/users/groups/${groupId}`;

  return api.get(url);
};

export const deleteUserToGroup = async (user, group) => {
  const url = "/groups/delete/user";

  return api.post(url, { groupId: group, userId: user });
};
