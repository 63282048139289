import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { BiMessageSquareAdd } from "react-icons/bi";
import { TiEdit } from "react-icons/ti";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GiCheckMark } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import moment from "moment/moment";
import {
    deleteGroup,
    getUsersByGroup,
    deleteUserToGroup,
} from "../../services/api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./customUiConfirm.css";

import { GrGroup } from "react-icons/gr";
import { toast } from "react-toastify";
import { ImEye } from "react-icons/im";

const Groups = ({ groups, loadGroups, countGroups }) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalUsers, setShowModalUsers] = useState(false);
    const [group, setGroup] = useState({});
    const [users, setUsers] = useState(null);
    const [groupId, setGroupId] = useState(null);

    const handleClose = () => setShowModal(false);
    const handleCloseModalUsers = () => setShowModalUsers(false);

    const handleShow = (id) => {
        setUsers(null);
        const group = groups.find((group) => group.id === id);
        setGroup(group);
        setShowModal(true);
    };

    const handleShowUsers = (id) => {
        handleUsersOnGroup(id);
        setGroup(id);
        setShowModalUsers(true);
    };

    const handleUsersOnGroup = async (id) => {
        const response = await getUsersByGroup(id);
        setGroupId(id);
        setUsers(response.data);
    };

    const deleteGroupView = async (id) => {
        confirmAlert({
            title: `Excluir grupo ${id}`,
            message: `Deseja realmente excluir o grupo?`,
            buttons: [
                {
                    label: "Sim",
                    onClick: async () => {
                        try {
                            await deleteGroup(id);
                            loadGroups();
                            setTimeout(() => {
                                toast.success("Grupo excluído com sucesso!");
                            }, 500);
                        } catch (err) {
                            toast.error(
                                "Grupo com pacientes vinculados! Não é possível excluir!"
                            );
                        }
                    },
                },
                {
                    label: "Não",
                    onClick: () => {},
                },
            ],
        });
    };

    const deleteUserToGroupView = async (idUser, idGroup) => {
        confirmAlert({
            title: `Excluir usuário ${idUser} do grupo ${idGroup}`,
            message: `Deseja realmente excluir o usuário do grupo?`,
            buttons: [
                {
                    label: "Sim",
                    onClick: async () => {
                        try {
                            await deleteUserToGroup(idUser, idGroup);
                            handleUsersOnGroup(idGroup);
                            setTimeout(() => {
                                toast.success(
                                    "Usuário excluído do grupo com sucesso!"
                                );
                            }, 500);
                            loadGroups();
                        } catch (err) {
                            toast.error("Erro ao excluir usuário do grupo!");
                        }
                    },
                },
                {
                    label: "Não",
                    onClick: () => {},
                },
            ],
        });
    };

    return (
        <div className="container-fluid mt-5">
            <div className="row">
                <div className="col-12 mt-2">
                    <div className="card mt-5">
                        <div className="card-header">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <h3 className="card-title display-5">
                                        Grupos (
                                        <span className="text-danger">
                                            {countGroups}
                                        </span>
                                        )
                                    </h3>
                                </div>
                                <div className="col-auto">
                                    <Link
                                        to="create"
                                        className="btn btn-primary"
                                    >
                                        {" "}
                                        <span>
                                            <BiMessageSquareAdd /> Criar
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <Table width={"100%"} responsive striped>
                                <thead>
                                    <tr className="text-center">
                                        <th>#</th>
                                        <th style={{ width: "30%" }}>
                                            Especialidade
                                        </th>
                                        <th>Vagas Restantes</th>
                                        <th>Qtd. Inscritos</th>
                                        <th>Dia</th>
                                        <th>Ativo</th>
                                        <th>Inscritos</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groups.map((group) => (
                                        <tr
                                            className="text-center"
                                            key={group.id}
                                        >
                                            <td className="fw-bold">
                                                {group.id}
                                            </td>
                                            <td>{group.expertise}</td>
                                            <td className="fw-bold text-success">
                                                {group.quantity}
                                            </td>
                                            <td className="fw-bold text-danger">
                                                {group.users.length}
                                            </td>
                                            <td>
                                                <Badge
                                                    pill
                                                    bg="info"
                                                    className="text-dark"
                                                >
                                                    {/* {moment(group.day).format(
                                                        "DD/MM/YYYY [às] HH:mm[h]"
                                                    )} */}
                                                    {moment
                                                        .utc(group.day)
                                                        .utcOffset(0)
                                                        .format(
                                                            "DD/MM/YYYY [às] HH:mm[h]"
                                                        )}
                                                </Badge>
                                            </td>
                                            <td>
                                                {group.active ? (
                                                    <Badge pill bg="primary">
                                                        <GiCheckMark />
                                                    </Badge>
                                                ) : (
                                                    <Badge pill bg="light">
                                                        <GrClose />
                                                    </Badge>
                                                )}
                                            </td>
                                            <td>
                                                {group.users.length > 0 ? (
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => {
                                                            handleShowUsers(
                                                                group.id
                                                            );
                                                        }}
                                                    >
                                                        <GrGroup
                                                            style={{
                                                                fill: "white",
                                                            }}
                                                        />{" "}
                                                        Listar
                                                    </Button>
                                                ) : (
                                                    <Badge pill bg="danger">
                                                        Não
                                                    </Badge>
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-info p-1 m-1"
                                                    style={{ minWidth: "85px" }}
                                                    onClick={() => {
                                                        handleShow(group.id);
                                                    }}
                                                >
                                                    <ImEye /> Exibir
                                                </button>
                                                <Link
                                                    to={`edit/${group.id}`}
                                                    className="btn btn-success p-1 m-1"
                                                    style={{ minWidth: "85px" }}
                                                >
                                                    {" "}
                                                    <span>
                                                        <TiEdit /> Editar
                                                    </span>
                                                </Link>
                                                <button
                                                    className="btn btn-danger p-1 m-1"
                                                    style={{ minWidth: "85px" }}
                                                    onClick={() => {
                                                        deleteGroupView(
                                                            group.id
                                                        );
                                                    }}
                                                >
                                                    <RiDeleteBin6Line /> Excluir
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header className="bg-info" closeButton>
                    <Modal.Title>{group.expertise}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <p>
                            <strong>Código:</strong> <span>{group.id}</span>
                        </p>
                        <p>
                            <strong>Descrição:</strong>{" "}
                            <span>{group.description}</span>
                        </p>
                        <p>
                            <strong>Quantidade de vagas:</strong>{" "}
                            <span>{group.quantity}</span>
                        </p>
                        <p>
                            <strong>
                                Data e Hora do início das consultas:
                            </strong>{" "}
                            <span>
                                {/* {moment(group.day).format(
                                    "DD/MM/YYYY [às] HH:mm[h]"
                                )} */}
                                {moment
                                    .utc(group.day)
                                    .utcOffset(0)
                                    .format("DD/MM/YYYY [às] HH:mm[h]")}
                            </span>
                        </p>
                        <p>
                            <strong>Ativo:</strong>{" "}
                            <span>
                                {group.active ? (
                                    <Badge pill bg="primary">
                                        Sim
                                    </Badge>
                                ) : (
                                    <Badge
                                        className="text-dark"
                                        pill
                                        bg="light"
                                    >
                                        Não
                                    </Badge>
                                )}
                            </span>
                        </p>
                        <p>
                            <strong>Criado em:</strong>{" "}
                            <span>
                                {/* {moment(group.createdAt).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                )} */}
                                {moment
                                    .utc(group.day)
                                    .utcOffset(0)
                                    .format("DD/MM/YYYY HH:mm:ss")}
                            </span>
                        </p>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalUsers}
                onHide={handleCloseModalUsers}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Lista de Pacientes Inscritos no Grupo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table width={"100%"} responsive striped>
                        <thead>
                            <tr className="text-center">
                                <th>#</th>
                                <th>Nome</th>
                                <th>Telefone</th>
                                <th>E-mail</th>
                                <th>Ativo</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users ? (
                                users.map((user) => (
                                    <tr className="text-center" key={user.id}>
                                        <td className="fw-bold">{user.id}</td>
                                        <td>{user.name}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.email}</td>
                                        <td>
                                            {user.active ? (
                                                <Badge pill bg="primary">
                                                    <GiCheckMark />
                                                </Badge>
                                            ) : (
                                                <Badge pill bg="light">
                                                    <GrClose />
                                                </Badge>
                                            )}
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-danger p-1 m-1"
                                                style={{ minWidth: "85px" }}
                                                onClick={async () => {
                                                    setShowModalUsers(false);
                                                    await deleteUserToGroupView(
                                                        user.id,
                                                        group
                                                    );
                                                }}
                                            >
                                                <RiDeleteBin6Line />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="text-center">
                                    <td>nada</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModalUsers}>
                        Fechar
                    </Button>
                    {groupId ? (
                        <Link
                            to={`/admin/print/users/on/group/${groupId}`}
                            className="btn btn-primary"
                            target="_blank"
                        >
                            Imprimir
                        </Link>
                    ) : null}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Groups;
