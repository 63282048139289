import React from "react";
import { Link } from "react-router-dom";

const NotAuthorizedPage = () => (
    <>
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <h1 className="display-1 fw-bold">401</h1>
                <p className="fs-3"> <span class="text-danger">Erro!</span> Não autorizado!</p>
                <p className="lead">
                    Você não tem permissão para acessar esta página.
                </p>
                <Link to="/" className="btn btn-primary">Início</Link>
            </div>
        </div>
    </>
);

export default NotAuthorizedPage;