import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";
import NavBar from "../Components/Navbar";
import { Link, useParams } from "react-router-dom";
import NotAuthorizedPage from "../NotAuthorizedPage";
import { TiArrowBackOutline } from "react-icons/ti";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Loading from "../Components/Loading";
import { HiUserGroup } from "react-icons/hi";
import { getGroup, updateGroup } from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment/moment";
import { TbEdit } from "react-icons/tb";

const Edit = () => {
    const { user, logout } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState(false);

    const { id } = useParams();

    const [form, setState] = useState({
        expertise: "",
        description: "",
        quantity: 1,
        day: "",
        active: true,
    });

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const response = await getGroup(id);
                setState({
                    expertise: response.data.expertise,
                    description: response.data.description,
                    quantity: response.data.quantity,
                    day: moment
                        .utc(response.data.day)
                        .utcOffset(0)
                        .format("YYYY-MM-DDTHH:mm"),
                    active: response.data.active,
                });
                setTimeout(() => {
                    setLoading(false);
                }, 50);
            } catch (err) {
                //console.error(err);
                //setLoadingError(true);
            }
        })();
    }, [id]);

    const handleLogout = () => {
        logout();
    };

    const editGroupView = async (e) => {
        const formSubmitted = e.currentTarget;
        if (formSubmitted.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        } else {
            e.preventDefault();
            e.stopPropagation();
            try {
                const result = await updateGroup(id, form);

                if (result.status === 200) {
                    toast.success("Grupo atualizado com sucesso!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                // Limpar a validação do formulário
                setValidated(false);
            } catch (err) {
                toast.error("Erro ao tentar atualizar grupo!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    const updateField = (e) => {
        setState({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const onActiveChange = (e) => {
        setState({
            ...form,
            active: e.target.checked,
        });
    };
    if (loading) {
        return <Loading />;
    }

    if (user.role !== "ADMIN") {
        return <NotAuthorizedPage />;
    }

    return (
        <>
            <NavBar onLogout={handleLogout} role={user.role} />
            <div className="container-fluid mt-5">
                <ToastContainer />
                <div className="row justify-content-center">
                    <div className="col-md-6 mt-2">
                        <div className="card mt-5">
                            <div className="card-header bg-success text-white">
                                <h3 className="card-title text-center">
                                    Editar grupo <HiUserGroup />
                                </h3>
                            </div>
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="shadow-lg p-3 mb-5 bg-body rounded">
                                            <Form
                                                noValidate
                                                validated={validated}
                                                onSubmit={editGroupView}
                                            >
                                                <h3 className="text-center">
                                                    Código:{" "}
                                                    <span className="text-danger">
                                                        {" "}
                                                        {id}{" "}
                                                    </span>
                                                </h3>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                        Especialidade
                                                    </Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="Digite a especialidade"
                                                        name="expertise"
                                                        value={form.expertise}
                                                        onChange={updateField}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Por favor, digite uma
                                                        especialidade.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                        Descrição
                                                    </Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="Digite a descrição"
                                                        name="description"
                                                        value={form.description}
                                                        onChange={updateField}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Por favor, digite uma
                                                        descrição.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                        Quantidade de Pacientes
                                                    </Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="number"
                                                        placeholder="Digite a quantidade de membros"
                                                        min={0}
                                                        name="quantity"
                                                        value={form.quantity}
                                                        onChange={updateField}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Por favor, digite a
                                                        quantidade de vagas.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                        Dia da Semana
                                                    </Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="datetime-local"
                                                        placeholder="Digite o dia da semana"
                                                        min={form.day}
                                                        name="day"
                                                        value={form.day}
                                                        onChange={updateField}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Por favor, escolha a
                                                        data e hora das
                                                        consultas.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Check
                                                        type="switch"
                                                        label="Ativo"
                                                        name="active"
                                                        defaultChecked={
                                                            form.active
                                                        }
                                                        onChange={
                                                            onActiveChange
                                                        }
                                                    />
                                                </Form.Group>
                                                <Button
                                                    variant="success"
                                                    type="submit"
                                                >
                                                    <TbEdit />
                                                    Editar
                                                </Button>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                                <Link
                                    to="/admin/groups"
                                    className="btn btn-success mt-2"
                                >
                                    {" "}
                                    <TiArrowBackOutline />
                                    Voltar
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Edit;
