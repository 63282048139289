import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";
import { getUsersByGroup } from "../../services/api";
import Loading from "../Components/Loading";
import { useParams } from "react-router-dom";

const PrintUsersOnGroupPage = () => {
    const { loading } = useContext(AuthContext);
    const { id } = useParams();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const response = await getUsersByGroup(id);
                setUsers(response.data);

                setTimeout(() => {
                    window.print();
                }, 2000);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    if (loading) return <Loading />;

    return (
        <div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Telefone</th>
                        <th>Endereço</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.name}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.address}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PrintUsersOnGroupPage;
