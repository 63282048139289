import React from "react";
import { Link } from "react-router-dom";
import {MdLogout} from 'react-icons/md';

const NavBar = ({onLogout, role, currentPage})  => {

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top pb-2" style={{ backgroundColor: 'rgb(50, 63, 119)' }}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    {/* <img src="/consulta.png" alt="" width="30" height="24" className="d-inline-block align-text-top" /> */}
                    <svg viewBox="0 0 36 36" fill="none" role="img" xmlns="http://www.w3.org/2000/svg" width="30" height="24">
                        <title>Fabiola Cabeza</title>
                        <mask id="mask__beam" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
                            <rect width="36" height="36" rx="72" fill="#FFFFFF"></rect>
                        </mask>
                        <g mask="url(#mask__beam)">
                            <rect width="36" height="36" fill="#e0dba4"></rect>
                            <rect x="0" y="0" width="36" height="36" transform="translate(8 -4) rotate(198 18 18) scale(1)" fill="#7ebea3" rx="6"></rect>
                            <g transform="translate(4 0) rotate(-8 18 18)">
                            <path d="M15 19c2 1 4 1 6 0" stroke="#000000" fill="none" stroke-linecap="round"></path>
                            <rect x="11" y="14" width="1.5" height="2" rx="1" stroke="none" fill="#000000"></rect>
                            <rect x="23" y="14" width="1.5" height="2" rx="1" stroke="none" fill="#000000"></rect>
                            </g>
                        </g>
                    </svg>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav me-auto">
                        <Link className={`nav-link ${currentPage === 'inicio' ? "active" : ''}`} to="/">Início</Link>
                        {
                            role === 'ADMIN' ? <Link className={`nav-link ${currentPage === 'adminGroupPage' ? "active" : ''}`} to="/admin/groups">Grupos</Link> : role === 'PATIENT' ? <Link className={`nav-link ${currentPage === 'patientGroupPage' ? "active" : ''}`} to="/patient/groups">Vagas</Link> : ''
                        }
                        {
                            role === 'ADMIN' ? '' : role === 'PATIENT' ? <Link className={`nav-link ${currentPage === 'patientAppointments' ? "active" : ''}`} to="/patient/sync/groups">Meus Agendamentos</Link> : '' 
                        }
                    </div>
                    <div className="navbar-nav">
                        <button className="btn btn-sm btn-light" onClick={onLogout}><MdLogout /> Sair</button>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;