import React from "react";
import Carousel from "react-bootstrap/Carousel";

const Painel = () => (
    <>
        <div className="row justify-content-center">
            <div className="col-md-6">
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/campanha/janeiro_branco.avif"
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3 className="text-dark">Janeiro Branco</h3>
                            <p className="text-dark">
                                Cuidados com a saúde mental.
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/campanha/fevereiro_roxo_laranja.avif"
                            alt="Second slide"
                        />

                        <Carousel.Caption>
                            <h3>Fevereiro Laranja e Roxo</h3>
                            <p>
                                Laranja: Combate à leucemia/incentivo a doação
                                de sangue ou medula óssea
                            </p>
                            <p>
                                Roxo: Conscientização do Lúpus, Fibromialgia e
                                Alzheimer.
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/campanha/marco_lilas.avif"
                            alt="Third slide"
                        />

                        <Carousel.Caption>
                            <h3 className="text-dark">Março Lilas</h3>
                            <p className="text-dark">
                                Prevenção do câncer do colo do útero.
                            </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    </>
);

export default Painel;
