import React, { useState } from "react";
import moment from "moment/moment";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { QRCodeSVG } from "qrcode.react";
import CryptoJS from "crypto-js";

const GroupsForPatients = ({
    groups,
    loadGroups,
    countGroups,
    handleMakeAppointment,
    appointments,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [group, setGroup] = useState({});

    const handleClose = () => setShowModal(false);

    const handleShow = (id) => {
        const group = groups.find((group) => group.id === id);
        setGroup(group);
        setShowModal(true);
    };

    return (
        <div className="container-fluid mt-5">
            <div className="row justify-content-center">
                <div className="col-md-7 mt-2">
                    <div className="card mt-5">
                        <div
                            className={`card-header ${
                                appointments
                                    ? "bg-success text-white"
                                    : "bg-info"
                            } `}
                        >
                            <h3 className="display-5">
                                {appointments
                                    ? `Minhas Consultas Agendadas`
                                    : `Lista de Vagas`}
                            </h3>
                        </div>
                        <div className="card-body">
                            <Table width={"100%"} responsive striped>
                                <thead>
                                    <tr className="text-center">
                                        <th>#</th>
                                        <th>Especialidade</th>
                                        <th>Dia</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groups.map((group) => (
                                        <tr
                                            className="text-center"
                                            key={group.id}
                                        >
                                            <td>
                                                {" "}
                                                <strong>{group.id}</strong>{" "}
                                            </td>
                                            <td>{group.expertise}</td>
                                            <td style={{ fontSize: "18px" }}>
                                                <Badge
                                                    pill
                                                    bg={`${
                                                        appointments
                                                            ? "success text-white"
                                                            : "info"
                                                    } `}
                                                    className="text-dark"
                                                >
                                                    {/* {moment(group.day).format(
                                                        "DD/MM/YYYY [às] HH:mm[h]"
                                                    )} */}
                                                    {moment
                                                        .utc(group.day)
                                                        .utcOffset(0)
                                                        .format(
                                                            "DD/MM/YYYY [às] HH:mm[h]"
                                                        )}
                                                </Badge>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-info p-1 m-1"
                                                    style={{ minWidth: "85px" }}
                                                    onClick={() => {
                                                        handleShow(group.id);
                                                    }}
                                                >
                                                    {" "}
                                                    {appointments
                                                        ? "Comprovante"
                                                        : "Ver"}{" "}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header
                    className={`${
                        appointments ? "bg-success text-white" : "bg-info"
                    } `}
                    closeButton
                >
                    <Modal.Title>{group.expertise}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!appointments ? (
                        <>
                            <p>
                                <strong>Código:</strong> <span>{group.id}</span>
                            </p>
                            <p>
                                <strong>Descrição:</strong>{" "}
                                <span>{group.description}</span>
                            </p>
                            <p>
                                <strong>Quantidade de vagas:</strong>{" "}
                                <span className="text-primary">
                                    {group.quantity}
                                </span>
                            </p>
                            <p>
                                <strong>
                                    Data e Hora do início das consultas:
                                </strong>{" "}
                                <span>
                                    {/* {moment(group.day).format(
                                        "DD/MM/YYYY [às] HH:mm[h]"
                                    )} */}
                                    {moment
                                        .utc(group.day)
                                        .utcOffset(0)
                                        .format("DD/MM/YYYY [às] HH:mm[h]")}
                                </span>
                            </p>
                        </>
                    ) : (
                        ""
                    )}

                    {appointments ? (
                        <>
                            <p style={{ textAlign: "center" }}>
                                <QRCodeSVG
                                    value={CryptoJS.AES.encrypt(
                                        `Grupo: ${group.id}, Especialidade: ${group.expertise}.`,
                                        "wolflan"
                                    ).toString()}
                                />
                            </p>
                            <p
                                className="text-danger"
                                style={{ textAlign: "center" }}
                            >
                                *Não comparecer à consulta acarretará no
                                bloqueio de sua conta. Caso ocorra algum
                                imprevisto entre em contato com o setor de
                                Consultas Médicas antes da data marcada.
                            </p>
                        </>
                    ) : (
                        <p className="text-danger">
                            *Os pacientes serão atendidos na ordem de chegada.
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    {appointments ? (
                        ""
                    ) : (
                        <Button
                            variant="primary"
                            onClick={() => {
                                handleMakeAppointment(group.id);
                                handleClose();
                            }}
                        >
                            Marcar Consulta
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default GroupsForPatients;
