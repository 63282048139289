import React, {useState, useContext, useEffect} from "react";
import { AuthContext } from "../../contexts/auth";
import NotAuthorizedPage from "../NotAuthorizedPage";
import NavBar from "../Components/Navbar";
import { getGroups, syncUserToGroup } from "../../services/api";
import Loading from "../Components/Loading";
import GroupsForPatients from "../Components/GroupsForPatients";
import Pagination from "../Components/Pagination";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PatientGroupPage = () => {
    const {user, logout} = useContext(AuthContext);

    const [loading, setLoading] = useState(true);

    const [groups, setGroups] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);

    const loadGroups = async () => {
        try {
            setLoading(true);
            const response = await getGroups(user.role, user.id);
            
            setGroups(response.data);
            
            setLoading(false);
        } catch (err) {
            //console.error(err);
            //setLoadingError(true);
        }
    }

    useEffect(() => {
        (loadGroups)();
    }, []);

    const handleMakeAppointment = async(groupId) => {
        try {
            const result = await syncUserToGroup(user.id, groupId);

            console.log(result);
            if(result.status === 200) {
                toast.success('Marcação realizada com sucesso! Você receberá um email de confirmação!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if(result.status === 400) {
                toast.error('Não foi possível realizar a marcação!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

            setGroups(groups.filter(group => group.id !== groupId));
        } catch (err) {
            //console.error(err);
            //setLoadingError(true);
        }
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = groups.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(groups.length / recordsPerPage);

    if (loading) {
        return (
            <Loading />
        )
    }

    if(user.role !== "ADMIN" && user.role !== "PATIENT") {
        return (
            <NotAuthorizedPage />
        )
    }

    const handleLogout = () => {
        logout();
    };

    return (
        <>
            <NavBar onLogout={handleLogout} role={user.role} currentPage={'patientGroupPage'}/>
            <ToastContainer />
            <GroupsForPatients countGroups={groups.length} groups={currentRecords} loadGroups={loadGroups} handleMakeAppointment={handleMakeAppointment} />
            <div className="row m-1">
                <div className="col-12">
                    <Pagination
                        className=""
                        totalItems={groups.length}
                        itemsPerPage={recordsPerPage}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                    />
                </div>
                <div className="col-12">
                    <p className="text-center">Página {currentPage} de {nPages}</p>    
                </div>
            </div>
        </>
    )
}

export default PatientGroupPage;