import React from "react";

const Loading = () => (
    <div className="d-flex justify-content-center align-items-center bg-white bg-opacity-100" style={{ minHeight: '100vh' }}>
        <div className="spinner-border text-primary" style={{ width: '5rem', height: '5rem'}} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
);

export default Loading;