import React, {useState, useContext} from "react";
import "./styles.css";
import { SlActionRedo } from "react-icons/sl";
import { FcFeedback, FcKey } from "react-icons/fc";
import {BiInfoCircle} from "react-icons/bi";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AuthContext } from "../../contexts/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginPage = () => {
    const { login } = useContext(AuthContext);

    // Modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // Close Modal

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async() => {
        try {
            await login(email, password);
        } catch(err) {
            if(!email || !password) {
                toast.error('Preencha todos os campos!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "custom-id-no",
                });
            } else {
                toast.error('Email e/ou senha incorretos!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: "custom-id-yes",
                });
            }
        }
    }

    return (
        <div className="Auth-form-container">
            <ToastContainer />
            <form onSubmit={
                (e) => {
                    e.preventDefault();
                    handleLogin();
                }
            }>
                <div className="Auth-form">
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">Login</h3>
                        <div className="form-group mt-3">
                            <label><FcFeedback fontSize={'18px'} /> Email</label>
                            <input
                                type="email"
                                className="form-control mt-1"
                                placeholder="Digite seu email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label><FcKey fontSize={'18px'} /> Senha</label>
                            
                            <input
                                type="password"
                                className="form-control mt-1"
                                placeholder="Digite sua senha"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button onClick={handleLogin} className="btn btn-primary">
                                <SlActionRedo /> Entrar
                            </button>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <Button variant="warning" onClick={handleShow}>
                                <BiInfoCircle /> Saiba Mais
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
            
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Novidade!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ textAlign: "justify" }}>
                        Com a nossa aplicação de marcação de consultas, 
                        agendá-la nunca foi tão fácil! 
                        Basta selecionar o melhor horário para você, confirmar sua informação de contato e pronto! 
                        Não perca mais tempo ligando para marcar uma consulta - faça isso online com nossa aplicação. Experimente agora!
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
} 

export default LoginPage;